import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Contact from "../Contact/Contact";
import Footer from "../Footer/Footer";
import Landing from "../Landing/Landing";
import Navbar from "../NavBar/Navbar";
import NavbarMenu from "../NavbarMenu/NavbarMenu";
import { Button } from "react-bootstrap";
import paTopIcon from "../../image/newImg/pa.png";
import "./Content.css";

function Parents(props) {
  const { cover, sidebar_data, side_heading, side_sub_heading } = props;
  const [isScrolled, setIsScrolled] = useState(false);
  const [isShow, steIsShow] = useState(false);
  const handleScroll = (e) => {
    if (e.target.scrollTop > 0 && !isScrolled) {
      setIsScrolled(true);
    }
    if (e.target.scrollTop === 0) {
      setIsScrolled(false);
    }
  };
  const handleClick = () => {
    steIsShow(!isShow);
    console.log(isShow);
  };

  return (
    <div className="content_main" onScroll={handleScroll}>
      <Navbar isScrolled={isScrolled} handleClick={handleClick} />
      <NavbarMenu isShow={isShow} handleClick={handleClick} />
      <Landing video={false} cover={cover} />
      <div className="contentmain_cont">
        <div className="conatct-box">
          <Contact />
        </div>
        <div>
          {/* parents' voice */}
          <div className={`f-2 f-500 mb-3`} style={{ color: "#1FDC00ED" }}>
            <img
              src={paTopIcon}
              style={{ width: "4rem", marginRight: "1rem" }}
            />
            Parent's Area
          </div>
          <div className="d-flex justify-content-between">
            <div>
              <NavLink to="/student-tc">
                <Button className="apply-btn cursor mx-2">Student TC</Button>
              </NavLink>
            </div>
            <div>
              <NavLink to="/new-registration">
                <Button className="apply-btn cursor mx-2">
                  New Registration
                </Button>
              </NavLink>
            </div>
            <div>
              <NavLink to="/existing-fees">
                <Button className="apply-btn cursor mx-2">Fees Payment</Button>
              </NavLink>
            </div>
          </div>

          {/*  */}

          {/* students' voice */}
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default Parents;
